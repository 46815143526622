export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T[P] extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : DeepPartial<T[P]> | T[P];
};

export function isRealObject(obj: unknown): obj is Record<string, unknown> {
  return Boolean(obj && typeof obj === 'object' && !Array.isArray(obj));
}

export function mergeFirstLevel<T extends Record<string, unknown>>(
  original: Readonly<Record<string, unknown>> | undefined,
  update: Readonly<DeepPartial<T>>
): T {
  if (!original) {
    return update as T;
  }

  const copy = {
    ...original,
  };

  Object.entries(update).forEach(([key, value]) => {
    const originalValue = copy[key];
    // original didn't have the key => add it
    if (!originalValue) {
      copy[key] = value;
      return;
    }

    // non-objects (including) arrays can't be merged => replace original value
    if (!isRealObject(originalValue) || !isRealObject(value)) {
      copy[key] = value;
      return;
    }

    // both original and update contain the key and both values are objects => merge first level
    copy[key] = {
      ...originalValue,
      ...value,
    };
  });

  return copy as T;
}
