import { ITrackingServiceV1, ITrackingData, IComponentData } from './types';

import { GlobalTrackingServiceV1 } from './GlobalTrackingService';
/**
 * Implementation of the tracking service interface via delegation to the {@link GlobalTrackingServiceV1}.
 */
export class ConsumerTrackingServiceV1 implements ITrackingServiceV1 {
  public featureAppId?: string;

  public featureAppName?: string;

  public constructor(private readonly delegate: GlobalTrackingServiceV1, defaultId: string) {
    this.featureAppId = defaultId;
  }

  public track(data: ITrackingData): void {
    this.delegate.track(data, this.featureAppId, this.featureAppName);
  }

  public updateComponent(data: IComponentData): void {
    this.delegate.updateComponent(data, this.featureAppId);
  }

  public getDatalayer(): { [key: string]: unknown } {
    return this.delegate.getDatalayer();
  }
}
