import {
  FeatureServiceBinder,
  FeatureServiceProviderDefinition,
  FeatureServices,
  SharedFeatureService,
} from '@feature-hub/core';

import { GlobalTrackingServiceV1 } from './v1/GlobalTrackingService';

import { ConsumerTrackingServiceV1 } from './v1/ConsumerTrackingService';
import { Logger } from './feature-service-types';

import { ITrackingServiceV1 } from './v1/types';
import {
  ConsumerTrackingServiceV2,
  DataLayerV2,
  SharedTrackingServiceV2,
  TrackingServiceV2,
} from './v2';

export * from './v1/types';
export * from './v2/types';
export * from './sanitize';

export interface SharedTrackingService extends SharedFeatureService {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly '1.0.0': FeatureServiceBinder<ITrackingServiceV1>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly '2.1.0': FeatureServiceBinder<TrackingServiceV2>;
}

export interface TrackingServiceOptions {
  /**
   * The datalayer object where events should be written, commonly found at `window.digitalData`.
   *
   * This object will be modified by the service!
   */
  datalayer: DataLayerV2;

  /**
   * A function that removes all personally identifiable information (PII) from the given URL. This includes for
   * example user IDs and VINs.
   *
   * You can use `createVinByRegExpRemover` as a sensible default. Any custom method should still incorporate
   * `createVinByRegExpRemover` to make sure that all VINs are removed correctly.
   */
  sanitizeUrl: (url: string) => string;
}

export interface OptionalTrackingServiceDependencies extends FeatureServices {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly 's2:logger'?: Logger;
}

const optionalDependencies: Record<keyof OptionalTrackingServiceDependencies, string> = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  's2:logger': '^1.0.0',
};

/**
 * This feature service provides tracking functionality to feature apps.
 */
export function defineTrackingService(
  trackingProvider: TrackingServiceOptions
): FeatureServiceProviderDefinition<SharedTrackingService, OptionalTrackingServiceDependencies> {
  return {
    id: 'audi-tracking-service',

    optionalDependencies: {
      featureServices: optionalDependencies,
    },

    create(env): SharedTrackingService {
      const { datalayer = {}, sanitizeUrl } = trackingProvider;
      if (!datalayer || !sanitizeUrl) {
        throw new Error(
          `The tracking Service is not properly configured. datalayer: ${datalayer}, sanitizeUrl: ${sanitizeUrl}`
        );
      }
      // The global service is featureApp independent and has no state. It can be instantiated once.
      const globalTrackingServiceV1 = new GlobalTrackingServiceV1(datalayer);
      const globalTrackingServiceV2 = new SharedTrackingServiceV2(
        datalayer,
        sanitizeUrl,
        env.featureServices['s2:logger'] || console
      );

      return {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '1.0.0': (consumerId) => ({
          featureService: new ConsumerTrackingServiceV1(globalTrackingServiceV1, consumerId),
        }),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '2.1.0': (consumerId) => {
          const featureService = new ConsumerTrackingServiceV2(globalTrackingServiceV2, consumerId);

          return {
            featureService,
            unbind: () => featureService.unbind(),
          };
        },
      };
    },
  };
}
