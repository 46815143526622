import {
  TrackingServiceV2 as TrackingService,
  TrackingDataV2 as TrackingData,
  ComponentAdditionsV2 as ComponentAdditions,
  UserAdditionsV2 as UserAdditions,
  DataLayerV2 as DataLayer,
  InternalLinkTrackingDataV2 as InternalLinkTrackingData,
  UnregisterImpressionTrackingV2 as UnregisterImpressionTracking,
} from './types';
import { SharedTrackingServiceV2 as SharedTrackingService } from './shared';

export class ConsumerTrackingServiceV2 implements TrackingService {
  private cancelRegisteredImpressions: Set<UnregisterImpressionTracking> = new Set();

  private savedFeatureAppName?: string;

  public featureAppId: string;

  constructor(private proxy: SharedTrackingService, defaultId: string) {
    this.featureAppId = defaultId;
  }

  public get featureAppName(): string | undefined {
    return this.savedFeatureAppName;
  }

  public set featureAppName(featureAppName: string | undefined) {
    this.savedFeatureAppName = featureAppName;
    this.proxy.setFeatureAppName({ id: this.featureAppId, name: this.featureAppName });
  }

  track(data: TrackingData): void {
    this.proxy.track({ id: this.featureAppId, name: this.featureAppName }, data);
  }

  trackInternalNavigation(data: InternalLinkTrackingData): void {
    this.proxy.trackInternalNavigation({ id: this.featureAppId, name: this.featureAppName }, data);
  }

  registerImpressionTracking(element: Element, dataCallback: () => TrackingData): () => void {
    const unregister = this.proxy.registerImpressionTracking(
      { id: this.featureAppId, name: this.featureAppName },
      element,
      dataCallback
    );

    this.cancelRegisteredImpressions.add(unregister);

    return unregister;
  }

  updateComponent(componentUpdate: ComponentAdditions): void {
    this.proxy.updateComponent(
      { id: this.featureAppId, name: this.featureAppName },
      componentUpdate
    );
  }

  updateUser(data: UserAdditions): void {
    this.proxy.updateUser(data);
  }

  evaluateLinkType(targetUrl: string): string | undefined {
    return this.proxy.evaluateLinkType(targetUrl);
  }

  getDatalayer(): DataLayer {
    return this.proxy.getDatalayer();
  }

  sanitizeUrl(url: string): string {
    return this.proxy.sanitizeUrl(url);
  }

  unbind(): void {
    this.cancelRegisteredImpressions.forEach((unregister) => unregister());
    this.cancelRegisteredImpressions.clear();

    this.proxy.removeComponent(this.featureAppId);
  }
}
