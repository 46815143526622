import React, { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import {
  IPersonalizationInfo,
  IPersonalizationServiceV1,
} from '@volkswagen-onehub/audi-personalization-service';

interface PersonalizationContextState {
  readonly useCase?: string;
  readonly personalizationInfo?: IPersonalizationInfo;
}

const PersonalizationContext = createContext<PersonalizationContextState | undefined>(undefined);

export interface PersonalizationContextProviderProps {
  readonly personalizationService?: IPersonalizationServiceV1;
  readonly useCase?: string;
}

export function PersonalizationContextProvider({
  children,
  personalizationService,
  useCase,
}: PropsWithChildren<PersonalizationContextProviderProps>) {
  const personalizationInfo = useCase
    ? personalizationService?.getUseCaseVariantInfo(useCase)
    : undefined;

  const contextValue = useMemo(
    () => ({
      useCase,
      personalizationInfo,
    }),
    [useCase, personalizationInfo],
  );

  return (
    <PersonalizationContext.Provider value={contextValue}>
      {children}
    </PersonalizationContext.Provider>
  );
}

export const usePersonalizationContext = () => {
  const state = useContext(PersonalizationContext);
  if (!state) {
    throw new Error(`Personalization context couldn't be found.
    Did you forget to add 'PersonalizationContextProvider'?
    You need to wrap your components with this provider to use this function:
    <PersonalizationContextProvider personalizationService={personalizationService} useCase="my-use-case">
      <YOUR_COMPONENT />
    </PersonalizationContextProvider>`);
  }
  return state;
};
