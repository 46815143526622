/* eslint-disable no-restricted-syntax */
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import {
  EnumerableFootnote,
  AudiFootnoteReferenceServiceInterfaceV3,
} from '@oneaudi/footnote-reference-service';

type FootnoteCallback = (footnote: EnumerableFootnote) => void;

interface FootnoteContextState {
  readonly registerFootnoteReference?: (
    reference: string,
    callback: FootnoteCallback,
  ) => () => void;
}

const FootnoteContext = createContext<FootnoteContextState>({});

export interface FootnoteContextProviderProps {
  readonly footnoteReferenceService: AudiFootnoteReferenceServiceInterfaceV3;
}

export function FootnoteContextProvider({
  children,
  footnoteReferenceService,
}: PropsWithChildren<FootnoteContextProviderProps>) {
  const [footnotes, setFootnotes] = useState<EnumerableFootnote[]>([]);
  const callbacksByReference = useRef(new Map<string, Set<FootnoteCallback>>());

  const registerFootnoteReference = useCallback((reference: string, callback: FootnoteCallback) => {
    let callbacks = callbacksByReference.current.get(reference);

    if (callbacks) {
      callbacks.add(callback);
    } else {
      callbacks = new Set([callback]);
      callbacksByReference.current.set(reference, callbacks);
    }

    footnoteReferenceService.addFootnoteReferences([reference]);

    return () => callbacks.delete(callback);
  }, []);

  useEffect(() => {
    footnoteReferenceService.registerCallback(setFootnotes);

    return () => {
      footnoteReferenceService.unregisterCallback();
      callbacksByReference.current.clear();
    };
  }, []);

  useEffect(() => {
    for (const footnote of footnotes) {
      const callbacks = callbacksByReference.current.get(footnote.id);

      if (callbacks) {
        for (const callback of callbacks) {
          callback(footnote);
        }
      }
    }
  }, [footnotes]);

  const contextValue = useMemo(() => ({ registerFootnoteReference }), [registerFootnoteReference]);

  return <FootnoteContext.Provider value={contextValue}>{children}</FootnoteContext.Provider>;
}

// this is the main hook
export function useFootnotesService(footnoteId: string): EnumerableFootnote | undefined {
  const { registerFootnoteReference } = useContext(FootnoteContext);
  const [footnote, setFootnote] = useState<EnumerableFootnote>();

  if (!registerFootnoteReference) {
    throw new Error(`'registerFootnoteReference' couldn't be found.
    Did you forget to add 'FootnoteContextProvider'?
    You need to wrap your components with this provider to use footnotes:
    <FootnoteContextProvider footnoteReferenceService={footnoteReferenceService}>
      <YOUR_COMPONENT />
    </FootnoteContextProvider>`);
  }

  useEffect(() => registerFootnoteReference(footnoteId, setFootnote), [footnoteId]);

  return footnote;
}
