import { Logger } from '../feature-service-types';
import {
  TrackingDataV2 as TrackingData,
  ComponentAdditionsV2 as ComponentAdditions,
  UserAdditionsV2 as UserAdditions,
  DataLayerV2 as DataLayer,
  ComponentV2 as Component,
  DataLayerEventV2 as DataLayerEvent,
  TrackingEventV2 as TrackingEvent,
  InternalLinkTrackingDataV2 as InternalLinkTrackingData,
  UrlSanitizerV2 as UrlSanitizer,
} from './types';
import { mergeFirstLevel } from './utils';

export interface FeatureAppInfo {
  id: string;
  name: string | undefined;
}

type ObserverData = {
  [index in ObserverType]: {
    isVisible: boolean;
    timeoutId?: ReturnType<typeof setTimeout>;
  };
};

interface TrackingInfo {
  featureAppInfo: FeatureAppInfo;
  dataCallback: () => TrackingData;
  fired: boolean;
  observer: ObserverData;
}

interface Observer {
  observe: (node: Element) => void;
  unobserve: (node: Element) => void;
}

type ObserverType = 'Observer70' | 'Observer100';

const FEATURE_APP_NAME_UNKNOWN = 'unknown';

export class SharedTrackingServiceV2 {
  private observer: Observer;

  private impressionTrackingMap: Map<Element, TrackingInfo> = new Map<Element, TrackingInfo>();

  constructor(
    private readonly datalayer: DataLayer,
    public readonly sanitizeUrl: UrlSanitizer,
    private readonly logger: Logger
  ) {
    this.observer = this.createObserver();
  }

  public setFeatureAppName(featureApp: FeatureAppInfo): void {
    const existingComponent = this.getComponent(featureApp.id);

    if (!existingComponent) {
      this.createComponent(featureApp);
      return;
    }

    existingComponent.componentInfo.componentName = featureApp.name;
  }

  public track(featureApp: FeatureAppInfo, data: TrackingData): void {
    const { event, userUpdate, componentUpdate } = data;

    if (userUpdate) {
      this.updateUser(userUpdate);
    }

    if (componentUpdate) {
      this.updateComponent(featureApp, componentUpdate);
    }

    if (!event.eventInfo || typeof event.eventInfo.eventAction !== 'string') {
      this.logger.warn(
        `Dropping event with empty eventAction from ${featureApp.name} (id: ${featureApp.id}). This is most likely a bug in the feature app and should be fixed.`,
        event
      );
      return;
    }

    if (!this.datalayer.event) {
      this.datalayer.event = [];
    }

    this.datalayer.event.push(this.addEventAttributes(featureApp, event));
  }

  public trackInternalNavigation(featureApp: FeatureAppInfo, data: InternalLinkTrackingData): void {
    const event = mergeFirstLevel<TrackingEvent>(data.event, {
      attributes: {
        targetURL: this.sanitizeUrl(`${window.location.origin}${data.targetHref}`),
      },
    });

    this.track(featureApp, {
      ...data,
      event,
    });
  }

  public updateComponent(featureApp: FeatureAppInfo, componentUpdate: ComponentAdditions): void {
    this.assertComponent(featureApp);

    const componentIndex = this.getComponentIndex(featureApp.id);

    const cleanUpdate = {
      ...componentUpdate,
      componentInfo: {
        ...componentUpdate.componentInfo,
      },
    };
    delete cleanUpdate.componentInfo.componentID;
    delete cleanUpdate.componentInfo.componentName;

    const updatedComponent = mergeFirstLevel<Component>(
      this.datalayer.component?.[componentIndex] || {},
      cleanUpdate
    );

    // datalyer.component is defintiely defined after calling `assertComponent`
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.datalayer.component![componentIndex] = updatedComponent;
  }

  public updateUser(userUpdate: UserAdditions): void {
    const updatedUser = mergeFirstLevel<Record<string, unknown>>(
      this.datalayer.user || {},
      userUpdate
    );

    this.datalayer.user = updatedUser;
  }

  // eslint-disable-next-line class-methods-use-this
  public evaluateLinkType(targetUrl: string): string | undefined {
    const internalLinkAction = 'internal_link';
    const externalLinkAction = 'exit_link';

    if (targetUrl.startsWith('/')) {
      return internalLinkAction;
    }

    if (targetUrl.startsWith('http')) {
      if (targetUrl.startsWith(window.location.origin)) {
        return internalLinkAction;
      }
      return externalLinkAction;
    }

    return undefined;
  }

  public registerImpressionTracking(
    featureAppInfo: FeatureAppInfo,
    element: Element,
    dataCallback: () => TrackingData
  ): () => void {
    const trackingInfo: TrackingInfo = {
      featureAppInfo,
      dataCallback,
      fired: false,
      observer: {
        Observer70: { isVisible: false },
        Observer100: { isVisible: false },
      },
    };

    if (!this.impressionTrackingMap.has(element)) {
      this.impressionTrackingMap.set(element, trackingInfo);
      this.observer.observe(element);
    } else {
      throw new Error(
        `The element of the feature app ${featureAppInfo.name} (id: ${featureAppInfo.id}) is already registered: ${element} `
      );
    }

    return () => {
      this.removeImpressionObservors(element);
      this.impressionTrackingMap.delete(element);
    };
  }

  public getDatalayer(): DataLayer {
    return this.datalayer;
  }

  private getComponentIndex(featureAppId: string): number {
    return (
      this.datalayer.component?.findIndex(
        (component) => component?.componentInfo?.componentID === featureAppId
      ) ?? -1
    );
  }

  private getComponent(featureAppId: string): Component | undefined {
    return this.datalayer.component?.[this.getComponentIndex(featureAppId)];
  }

  private assertComponent(featureApp: FeatureAppInfo): void {
    if (this.getComponent(featureApp.id)) {
      return;
    }

    throw new Error(
      `Component for feature app with ID ${featureApp.id} does not exist. Did you forget to set trackingService.featureAppName?`
    );
  }

  private createComponent(featureApp: FeatureAppInfo): void {
    if (this.getComponent(featureApp.id)) {
      return;
    }

    if (!this.datalayer.component) {
      this.datalayer.component = [];
    }

    this.datalayer.component.push({
      componentInfo: {
        componentID: featureApp.id,
        componentName: featureApp.name || FEATURE_APP_NAME_UNKNOWN,
      },
    });
  }

  public removeComponent(featureAppId: string): void {
    const componentIndex = this.getComponentIndex(featureAppId);

    if (componentIndex >= 0) {
      this.datalayer.component?.splice(componentIndex, 1);
    }
  }

  private addEventAttributes(featureApp: FeatureAppInfo, event: TrackingEvent): DataLayerEvent {
    this.assertComponent(featureApp);

    const currentURL = this.sanitizeUrl(window.location.href);

    const automaticAttributes = {
      currentURL,
      relatedComponent: {
        featureAppID: featureApp.id,
        featureAppName: featureApp.name || FEATURE_APP_NAME_UNKNOWN,
        componentIndex: this.getComponentIndex(featureApp.id),
      },
    };

    const fullAttributes = mergeFirstLevel<typeof automaticAttributes>(
      event.attributes,
      automaticAttributes
    );

    return {
      ...event,
      attributes: fullAttributes,
    };
  }

  private createObserver(): Observer {
    /* This observer should trigger at any intersection in the upper 70%.
     * With the -30% in the rootMargin we reduce the intersection space to the bottom
     * and only the upper 70% of the space will trigger the observer.
     */
    const observer70 = new IntersectionObserver(this.visibilityCallback.bind(this, 'Observer70'), {
      root: null,
      rootMargin: '0px 0px -30% 0px',
      threshold: 0,
    });

    const observer100 = new IntersectionObserver(
      this.visibilityCallback.bind(this, 'Observer100'),
      {
        root: null,
        rootMargin: '0px',
        threshold: 1,
      }
    );

    return {
      observe(node: Element) {
        observer70.observe(node);
        observer100.observe(node);
      },
      unobserve(node: Element) {
        observer70.unobserve(node);
        observer100.unobserve(node);
      },
    };
  }

  private visibilityCallback(
    observerType: ObserverType,
    entries: IntersectionObserverEntry[]
  ): void {
    const delay = 1000;

    entries.forEach((intersectionEntry: IntersectionObserverEntry) => {
      let isVisible = intersectionEntry.intersectionRatio > 0;
      if (observerType === 'Observer100') {
        isVisible = intersectionEntry.intersectionRatio === 1;
      }

      const element = intersectionEntry.target;
      const elementTrackingInfo = this.impressionTrackingMap.get(element);

      if (!elementTrackingInfo || elementTrackingInfo.fired === true) {
        this.removeImpressionObservors(element);
        return;
      }
      const currentObserverData = elementTrackingInfo.observer[observerType];
      currentObserverData.isVisible = isVisible;

      if (isVisible && !currentObserverData.timeoutId) {
        currentObserverData.timeoutId = setTimeout(
          this.triggerTracking.bind(this, element, observerType),
          delay
        );
      } else if (!isVisible && currentObserverData.timeoutId) {
        clearTimeout(currentObserverData.timeoutId);
        currentObserverData.timeoutId = undefined;
      }
    });
  }

  private triggerTracking(element: Element, observerType: ObserverType) {
    const trackingInfo = this.impressionTrackingMap.get(element);

    if (
      trackingInfo &&
      trackingInfo.fired === false &&
      trackingInfo.observer[observerType].isVisible
    ) {
      trackingInfo.fired = true;
      this.removeImpressionObservors(element);

      this.track(trackingInfo.featureAppInfo, trackingInfo.dataCallback());
    } else if (!trackingInfo) {
      this.logger.warn(`Could not find tracking info for the element: '${element}'`);
    }
  }

  private removeImpressionObservors(element: Element) {
    this.observer.unobserve(element);
    const trackingInfo = this.impressionTrackingMap.get(element);

    if (trackingInfo) {
      Object.values(trackingInfo.observer).forEach((o) => {
        if (o.timeoutId) {
          clearTimeout(o.timeoutId);
          // eslint-disable-next-line no-param-reassign
          o.timeoutId = undefined;
        }
      });
    }
  }
}
