/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/ban-types */
import { ITrackingData, IComponentData } from './types';

/*
 * (Some) Datalayer interfaces expressing our expectations of the CEDDL structure at Audi.
 */
export interface IDatalayerData {
  page?: IDatalayerPageData;
  component?: IDatalayerComponentData[];
  event?: object[];
  [key: string]: unknown;
}

export interface IDatalayerPageData {
  pageInfo?: IDataLayerPageInfoData;
  attributes?: object;
  [key: string]: unknown;
}

export interface IDataLayerPageInfoData {
  destinationURL?: string;
  [key: string]: unknown;
}

export interface IDatalayerComponentData {
  componentInfo?: { [key: string]: unknown };
  [key: string]: unknown;
}

export interface IEventData {
  attributes?: { [key: string]: unknown };
  [key: string]: unknown;
}

/**
 * Implementation of the tracking service used by all featureApps and other services.
 * The featureApp specific data must be passed to this service via method parameters as shown by the featureApp
 * specific {@link ConsumerTrackingServiceV1} that delegates to this global service.
 */
export class GlobalTrackingServiceV1 {
  /**
   * Merges one selected property of two dictionaries.
   * If the property is an array it is merged by the spread operator for arrays and objects.
   * Otherwise the property is replaced.
   *
   * @param key selects the property that will be merged
   * @param added additional data
   * @param current current data AND object that the added data will be merged to.
   */
  private static mergeForKey(
    key: string,
    added: { [key: string]: unknown },
    current: { [key: string]: unknown }
  ): { [key: string]: unknown } {
    const addedData = added[key];

    const currentData = current[key];
    if (!currentData) {
      current[key] = addedData;
    } else if (Array.isArray(currentData) && Array.isArray(addedData)) {
      current[key] = [...currentData, ...addedData];
    } else if (typeof currentData === 'object' && typeof addedData === 'object') {
      current[key] = { ...currentData, ...addedData };
    } else {
      current[key] = addedData;
    }

    return current;
  }

  /**
   * Adds the featureApp description to an event.
   */
  private static enrichWithFeatureAppDesc(
    event: IEventData,
    featureAppId?: string,
    featureAppName?: string
  ): IEventData {
    if (event.attributes) {
      if (!event.attributes.featureAppId) {
        event.attributes.featureAppId = featureAppId || 'unknown';
      }
      if (!event.attributes.featureAppName) {
        event.attributes.featureAppName = featureAppName || 'unknown';
      }
    } else {
      event.attributes = {
        featureAppId: featureAppId || 'unknown',
        featureAppName: featureAppName || 'unknown',
      };
    }
    return event;
  }

  public constructor(private readonly datalayer: IDatalayerData) {}

  public track(data: ITrackingData, featureAppId?: string, featureAppName?: string): void {
    const { datalayer } = this;

    /*
     * Page data has a special handling.
     */
    const { pageAdditions } = data;
    if (pageAdditions) {
      const currentPageData = datalayer.page;
      if (!currentPageData) {
        datalayer.page = data.pageAdditions;
      } else {
        Object.keys(pageAdditions).forEach((pageKey) => {
          GlobalTrackingServiceV1.mergeForKey(pageKey, pageAdditions, currentPageData);
        });
      }
    }

    /*
     * ...now the rest - except for page and event:
     * The "event" is handled later as the event must be pushed onto the event array AFTER all other changes are applied
     */
    const { datalayerAdditions } = data;
    if (datalayerAdditions) {
      Object.keys(datalayerAdditions).forEach((key) => {
        if (!['page', 'event'].includes(key)) {
          GlobalTrackingServiceV1.mergeForKey(key, datalayerAdditions, datalayer);
        }
      });
    }

    /*
     * Enrichment with featureApp information.
     */
    const eventData = GlobalTrackingServiceV1.enrichWithFeatureAppDesc(
      data.event,
      featureAppId,
      featureAppName
    );
    /*
     * Push to the datalayer.
     */
    if (datalayer.event) {
      datalayer.event.push(eventData);
    }
  }

  public updateComponent(data: IComponentData, featureAppId?: string): void {
    const { datalayer } = this;

    if (datalayer && datalayer.component && featureAppId) {
      datalayer.component.forEach((entry) => {
        const currentComponentInfo = entry.componentInfo;
        if (
          currentComponentInfo &&
          currentComponentInfo.componentID &&
          (currentComponentInfo.componentID as string) === featureAppId
        ) {
          /*
           * We have found the feature apps component!
           */
          const { componentAdditions } = data;
          if (componentAdditions) {
            Object.keys(componentAdditions).forEach((key) => {
              if (!['subComponent'].includes(key)) {
                GlobalTrackingServiceV1.mergeForKey(key, componentAdditions, entry);
              }
            });
          }

          entry.subComponent = data.subComponentReplacement;
        }
      });
    }
  }

  public getDatalayer(): { [key: string]: unknown } {
    return this.datalayer;
  }
}
