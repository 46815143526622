import { Logger } from './feature-service-types';
import { UrlSanitizerV2 } from './v2';

// WMI list taken from https://www.kba.de/DE/Typgenehmigung/Fahrzeugtypdaten_amtlDaten_TGV/Auskuenfte_Informationen/Veroeffentlichungen/SV3.html
// additionally added BAU which is used for virtual (=> testing) VINs
const WMI = 'BAU|WAU|WA1|TRU|WUA|WU1';
const allowsCharacters = '0-9|A-H|J-N|P|R-Z'; // no I, O, Q

/**
 * Matches valid VINS whose WMI is associated with Audi and valid virtual VINs (with WMI "BAU")
 */
export const AUDI_VIN_REGEXP = new RegExp(`(?:${WMI})[${allowsCharacters}]{14}`);
/**
 * Matches any virtual VIN (with WMI "BAU"), even if it contains illegal characters
 */
// After eslint udate regular expression literal prefered instead of the 'RegExp' constructor
export const ALL_VIRTUAL_VIN_REGEXP = /BAU[0-9|A-Z]{14}/;
/**
 * Matches any valid VIN, even if not associated with Audi
 */
export const ANY_VIN_REGEXP = new RegExp(`[${allowsCharacters}]{3}[${allowsCharacters}]{14}`);

function removeVinByRegExp(url: string, logger: Logger): string {
  const replacement = 'vehicle-id';
  return (
    url
      // replace vin using strict format
      .replace(new RegExp(AUDI_VIN_REGEXP, 'ig'), replacement)
      // additional check and replacement for testing VINs including illegal characters
      .replace(new RegExp(ALL_VIRTUAL_VIN_REGEXP, 'ig'), () => {
        logger.warn('Possible virtual VIN with illegal characters found');

        return replacement;
      })
      // additional check and replacement using generic format
      .replace(new RegExp(ANY_VIN_REGEXP, 'ig'), (match) => {
        logger.warn(
          `Possible VIN with unhandled manufacturer identifier found: ${match.substr(0, 3)}...`
        );

        return replacement;
      })
  );
}

/**
 * The default way of removing personally identifiable information from the give URL.
 * Any URL sanitization method used with the tracking service should also incorporate this method.
 *
 * Replaces all matches of AUDI_VIN_REGEXP, ALL_VIRTUAL_VIN_REGEXP and ANY_VIN_REGEXP with the string "vehicle-id" and
 * logs a warning if either an invalid virtual VIN or a VIN that is not associated with Audi is found.
 */
export function createVinByRegExpRemover(logger: Logger = console): UrlSanitizerV2 {
  return (url: string) => removeVinByRegExp(url, logger);
}
