import React, { useEffect, useState } from 'react';
import {
  debug,
  isTrackingDisabled,
  readyEvent,
  impression,
  clickEvent,
  TrackingServiceV2,
  PartialEventData,
  setTargetURL,
  removeSpecialCharacters,
  setEventAction,
} from '@oneaudi/audi-tracking-service';

interface FeatureAppTrackingConfig {
  featureAppName: string;
  trackingService: TrackingServiceV2 | undefined;
  ref: React.MutableRefObject<HTMLElement | null> | null;
  attributes: {
    version: string;
    implementer?: number;
    [key: string]: unknown;
  };
}

export interface UseFeatureAppTrackingReturn {
  track: {
    sendReadyEvent: (customEvent?: PartialEventData) => void;
    registerImpressionTracking: (customEvent?: PartialEventData) => void;
    sendClickEvent: (clickTrackingAttributes: ClickTrackingAttributes) => void;
  };
}

export type OpenInTabOrLayer = 'page' | 'tab' | 'layer' | 'chatbot';
export interface ClickTrackingAttributes {
  url: string; // URL of the clicked element
  label: string; // 	Link text of the clicked element
  index: number;
  openInTabOrLayer: OpenInTabOrLayer;
  value: string;
}

const useFeatureAppTracking = ({
  featureAppName,
  trackingService,
  ref,
  attributes,
}: FeatureAppTrackingConfig): UseFeatureAppTrackingReturn => {
  const [_ref, setRef] = useState(ref);

  useEffect(() => {
    if (!trackingService) {
      return;
    }

    if (trackingService.featureAppName !== featureAppName) {
      // eslint-disable-next-line no-param-reassign
      trackingService.featureAppName = featureAppName;
    }

    if (ref?.current) {
      setRef(ref);
      debug(featureAppName, `Ref is defined. This will enable impression tracking`);
    } else {
      debug(featureAppName, `Ref is not defined. This will prevent impression tracking`);
    }
  }, [trackingService, featureAppName, ref?.current]);

  if (isTrackingDisabled() || !trackingService) {
    debug(
      featureAppName,
      `TRACKING DISABLED VIA URL PARAMS; ' ${!trackingService ? 'TRACKING SERVICE NOT DEFINED' : 'SERVICE DEFINED'}`,
    );

    return {
      track: {
        sendReadyEvent: () => {
          debug(featureAppName, 'TRACKING not executed: sendReadyEvent');
        },
        registerImpressionTracking: () => {
          debug(featureAppName, 'TRACKING not executed: registerImpressionTracking');
        },
        sendClickEvent: () => {
          debug(featureAppName, 'TRACKING not executed: sendClickEvent');
        },
      },
    };
  }
  const componentNameWithDashes = featureAppName.replace('fa-', '');
  const componentNameWithSpaces = componentNameWithDashes.replace(/-/g, ' ');
  const defaultComponentAdditions = {
    componentInfo: {},
    attributes,
  };
  const defaultEventArgs = {
    trackingService,
    componentNameWithSpaces,
    componentNameWithDashes,
    defaultComponentAdditions,
  };

  const track = {
    sendReadyEvent: readyEvent(defaultEventArgs),
    registerImpressionTracking: impression({ ...defaultEventArgs, ref: _ref! }),
    sendClickEvent: (clickTrackingAttributes: ClickTrackingAttributes) => {
      const { url, label, index, openInTabOrLayer, value } = clickTrackingAttributes;
      const clickEventData: PartialEventData = {
        event: {
          attributes: {
            label, // Link text of the clicked element
            targetURL: setTargetURL(url, openInTabOrLayer),
            relatedElements: [
              {
                elementName: index === 0 ? 'primary cta' : 'secondary cta',
                elementValue: `${index + 1}-${label}`,
              },
            ], // related elements contains the ctas used in the component
            componentName: `${componentNameWithSpaces}`,
            elementName: 'button',
            clickID: '', // fixed value
            pos: '', // fixed value
            value: removeSpecialCharacters(value),
          },
          eventInfo: {
            eventAction: setEventAction(trackingService, openInTabOrLayer, url),
            eventName: `${componentNameWithSpaces} - click on cta${openInTabOrLayer === 'layer' ? ' - layer open' : ''}`,
          },
        },
        componentUpdate: defaultComponentAdditions,
      };
      clickEvent(defaultEventArgs)(clickEventData);
    },
  };

  if (trackingService.featureAppName) {
    trackingService.enableDebugMode(trackingService.featureAppName, track);
  }

  return {
    track,
  };
};

export { useFeatureAppTracking };
